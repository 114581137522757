import * as React from 'react';
import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import '../styles/blog.css';
import slugify from 'slugify';

const BlogPage = ({ data }) => {
   console.log('blog-data', data);
   return (
      <>
         <Layout pageTitle="My Blog Posts">
            {/* ORIGINAL */}
            {/* <div className="blogContainer">
               <h2 className="blogH2">Blog Posts</h2>
               <ul>
                  {data.allMdx.nodes.map((node) => (
                     <article key={node.id}>
                        <h2>
                           <Link to={`/blog/${node.slug}`}>
                              {node.frontmatter.title}
                           </Link>
                        </h2>
                        <p>Posted: {node.frontmatter.date}</p>
                     </article>
                  ))}
               </ul>
            </div> */}
            {/* BLOG POSTS */}
            <ul>
               {data.allFile.nodes.map((node) => (
                  <article key={node.childMdx.id}>
                     {console.log('slugify-link', slugify(node.childMdx.slug))}
                     {console.log('node', node)}
                     <h2>
                        <Link to={`/blog/${slugify(node.childMdx.slug)}`}>
                           {node.childMdx.frontmatter.title}
                        </Link>
                     </h2>
                     <p>
                        Posted:
                        {node.childMdx.frontmatter.date}
                     </p>
                  </article>
               ))}
            </ul>
         </Layout>
      </>
   );
};

// export const query = graphql`
//    query {
//       allMdx(sort: { fields: frontmatter___date, order: DESC }) {
//          nodes {
//             frontmatter {
//                date(formatString: "MMMM D, YYYY")
//                title
//             }
//             id
//             slug
//          }
//       }
//    }
// `;

// export const query = graphql`
//    query {
//       allFile(
//          sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
//          filter: { sourceInstanceName: { eq: "blog" } }
//       ) {
//          nodes {
//             childMdx {
//                frontmatter {
//                   date(formatString: "MMMM D, YYYY")
//                   title
//                }
//                id
//                slug
//             }
//          }
//       }
//    }
// `;

// export const query = graphql`
//    query {
//       allFile(
//          sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
//          filter: { sourceInstanceName: { eq: "blog" } }
//       ) {
//          nodes {
//             childMdx {
//                frontmatter {
//                   date(formatString: "MMMM D, YYYY")
//                   title
//                }
//                id
//                slug
//             }
//          }
//       }
//    }
// `;

//https://stackoverflow.com/questions/51349801/querying-all-images-in-folder-using-graphql
//https://stackoverflow.com/questions/63082220/query-folder-usign-graphql-and-gatsby-source-filesystem

export const query = graphql`
   query {
      allFile(
         sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
         filter: {
            extension: { eq: "mdx" }
            sourceInstanceName: { eq: "blog" }
         }
      ) {
         nodes {
            childMdx {
               frontmatter {
                  date(formatString: "MMMM D, YYYY")
                  title
               }
               id
               slug
            }
         }
      }
   }
`;

export default BlogPage;

// filter: {
//    sourceInstanceName: {
//       eq: 'blog';
//    }
// }
